import type { TableCellProps } from '@mui/material'
import { TableCell, Typography } from '@mui/material'

export function TableCellHeader(props: TableCellProps): JSX.Element {
  return (
    <TableCell {...props}>
      <Typography variant="captionMedium">{props.children}</Typography>
    </TableCell>
  )
}
